






















import { useMutation } from "@vue/apollo-composable";
import { computed, defineComponent, ref } from "@vue/composition-api";

import BackLink from "@/app/components/Base/BackLink.vue";
import ProjectForm from "@/app/components/Project/ProjectForm.vue";
import router from "@/app/core/router";
import { decodeProject, encodeProject } from "@/models/Project";
import { PROJECT_DATA } from "@/shared/constants/components/Project/ProjectForm";
import { useRoute } from "@/shared/hooks";
import { useProject } from "@/shared/hooks/fetchers";
import { useNotification } from "@/shared/hooks/notification";
import { ProjectInputExt } from "@/shared/types/components/Project/Project";
import { Users } from "@/shared/types/components/Task/TaskForm";
import {
  ProjectInput,
  UpdateProjectDocument,
} from "@/shared/types/schema.types";

export default defineComponent({
  name: "edit-project",
  components: {
    ProjectForm,
    BackLink, 
  },
  setup() {
    const route = useRoute();
    const projectId = route.params.id;
    const { errNotification } = useNotification();

    const initialUsers = ref<Users>([]);
    const projectData = ref(
      _.cloneDeep(PROJECT_DATA),
    );

    const { loading, onResult } = useProject({ id: projectId });

    onResult((res) => {
      initialUsers.value = _.compact([res.data.project.coexecutor, res.data.project.executor]);
      projectData.value = decodeProject(res.data) as ProjectInputExt;
    });

    const { mutate: editProjectMutation, onError, onDone } = useMutation(UpdateProjectDocument);

    const btnDisabled = computed(
      () =>
        !Object.keys(
          _.pick(projectData.value, [
            "description",
            "executorId",
            "priorityCode",
            "deadline",
            "name",
          ]),
        ).every((el) => !!projectData.value[el as keyof ProjectInput]),
    );
    const backLink = computed(() =>`/projects/${projectId}`);

    const editProject = async(): Promise<void> => {
      const encodedResult = encodeProject(projectData.value, route.params.id);
      await editProjectMutation({
        projectInput: encodedResult.projectInput,
        projectId: encodedResult.projectId as string,
      });
      await router.push('/projects');
    };

    onDone(() => {
    //router && router.push("/projects");
    });
    onError((err) => {
      errNotification(err);
    });

    return {
      editProject,
      projectData,
      loading,
      editProjectMutation,
      initialUsers,
      btnDisabled,
      backLink,
    };
  },
});
